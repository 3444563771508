<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-alert type="error" dark outlined icon="mdi-lock" border="left" color="deep-orange accent-4" elevation="2">
          <div class="headline">Access Denied</div>
          <p>
            You do not have permission to access the
            <strong v-if="module">{{ module }}</strong>
            <span v-else>current</span>
            page. Please contact your administrator if you believe this is an error.
          </p>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    module() {
      return this.$route.query.module || '';
    },
  },
};
</script>
